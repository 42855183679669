<template>
  <div>
    <loading-overlay :active="isLoading" :is-full-page="true" loader="bars" />
    <CModalExtended
      :title="getTitle"
      color="dark"
      size="lg"
      :close-on-backdrop="false"
      @update:show="toggle(false)"
      :show.sync="modalActive"
      class="modal-extended"
    >
      <form @submit.stop.prevent="submit" class="company-form">
        <CRow>
          <CCol sm="12">
            <CCard class="card-simple">
              <CCardBody>
                <CRow>
                  <CCol sm="12"> 
                      <CSelect
                        v-model.trim="$v.cancelacion.movimientoId.$model"
                        :is-valid="hasError($v.cancelacion.movimientoId)"
                        :invalid-feedback="errorMessage($v.cancelacion.movimientoId)"
                        :label="$t('label.movement')"
                        addLabelClasses="required text-right"
                        :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                        :options="movimientosFormatted"
                        class="mt-1"
                        @change="(cancelacion.transactionId = '', getTransacciones)"
                      />
                  </CCol>
                  <CCol sm="12"> 
                      <CSelect
                        v-model.trim="$v.cancelacion.transactionId.$model"
                        :is-valid="hasError($v.cancelacion.transactionId)"
                        :invalid-feedback="errorMessage($v.cancelacion.transactionId)"
                        :label="$t('label.transaction')"
                        addLabelClasses=" required text-right"
                        :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                        :options="transaccionesFormatted"
                        class="mt-1"
                      />
                  </CCol>
                  <CCol sm="12"> 
                    <CInput
                      v-model.trim="$v.cancelacion.CancellationReasonEs.$model"
                      v-uppercase
                      :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                      :invalid-feedback="errorMessage($v.cancelacion.CancellationReasonEs)"
                      :is-valid="hasError($v.cancelacion.CancellationReasonEs)"
                      :label="$t('label.CancellationReason')+' (ES)'"
                      :placeholder="$t('label.CancellationReasonName')+' (ES)'"
                      addLabelClasses="required text-right"
                      maxlength="100"
                    >
                    </CInput>
                  </CCol>
                  <CCol sm="12"> 
                    <CInput
                      v-model.trim="$v.cancelacion.CancellationReasonEn.$model"
                      v-uppercase
                      :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                      :invalid-feedback="errorMessage($v.cancelacion.CancellationReasonEn)"
                      :is-valid="hasError($v.cancelacion.CancellationReasonEn)"
                      :label="$t('label.CancellationReason')+' (EN)'"
                      :placeholder="$t('label.CancellationReasonName')+' (EN)'"
                      addLabelClasses="required text-right"
                      maxlength="100"
                    >
                    </CInput>
                  </CCol>
                  <CCol sm="12"> 
                    <CSelect
                      :value.sync="cancelacion.Status"
                      :is-valid="statusSelectColor"
                      :label="$t('label.status')"
                      addLabelClasses="required text-right"
                      :horizontal="{ label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                      :options="statusOptions"
                      class="mt-1"       
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>         
      </form>
      <template #footer>
        <CButton
          color="add"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="changeStatus"
        >
            <CIcon name="checkAlt"/><span class="ml-1">{{$t('button.accept')}}</span>
        </CButton>
        <CButton
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="toggle(false)"
        >
          <CIcon name="x"/><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>

<script>
import CancelValidation from '@/_validations/estiba/CancelFormValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import { MovimientoMixin } from '@/_mixins/estiba';
import ModalMixin from '@/_mixins/modal';

//Data
function data() {
  return {
    modalActive: this.modal,
    isSubmit: false,
    cancelacion: {
      CancellationId: '',
      movimientoId: '',
      transactionId:'',
      CancellationReasonEn:'',
      CancellationReasonEs:'',
      Status: 1,
      statusOrigin: 1,
    },
    movimientos: [],
    movimiento: null,
    transacciones: [],
    transaccion: null,
    isLoading: false,
    titulo: '',
    
  }
}

//Methods
function submit() {
  try {
  this.isSubmit = true;
  this.isLoading = true;
  this.$v.cancelacion.$touch();
    if (this.$v.cancelacion.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }

    let MovStowageReasonJson = {
      MovStowageReasonId: this.cancelacion.CancellationId,
      TransacStowageId: this.cancelacion.transactionId,
      MovStowageReasonNameEs: this.cancelacion.CancellationReasonEs,
      MovStowageReasonName: this.cancelacion.CancellationReasonEn,
      Status: this.cancelacion.Status,
    };

    this.$http.put("MovStowageReason-update", MovStowageReasonJson, { root: 'MovStowageReasonJson' })
    .then(response => {
      this.$emit('submited');
      this.modalActive = false;
      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: response.data.data[0].Response,
        type: "success"
      });
      this.resetForm();
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isSubmit = false;
      this.isLoading = false;
    });
  } catch (e) {
    this.isSubmit = false;
    this.isLoading = false;
    this.notifyError({ text: e });
  }
}

function toggle(newVal) {
  if(!newVal)
    this.resetForm();

  this.modalActive = newVal;
}
function resetForm() {
  this.cancelacion.movimientoId='';
  this.cancelacion.transactionId='';
  this.cancelacion.CancellationReasonEn='';
  this.cancelacion.CancellationReasonEs='';
  this.cancelacion.Status=1,
  this.cancelacion.statusOrigin=1,
  this.movimientos=[];
  this.transacciones=[];
  this.$v.$reset();
}

  function changeStatus() {

    if(this.cancelacion.statusOrigin !== this.cancelacion.Status){              
      this.$swal
        .fire(this.alertProperties({
          text: `${this.$t('label.changeStatusQuestion')}?`,
        }))     
        .then((result) => {
        if (result.isConfirmed) {
            this.submit();
        }
      })
    }else{
      this.submit();
    }
  }


function statusSelectColor() {
  return (this.cancelacion.Status === 1)?true:false;
}


function getTitle() {
  return this.titulo;
}

function getMovimientos() {
  this.isLoading = true;
  this.$http.get("MovStowage-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.movimientos = response.data.data;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.isLoading = false;
  });
}


function getTransacciones() {
  if (this.cancelacion.movimientoId != ''){
    this.isLoading = true;
    this.$http.get("TransacStowage-list", { MovStowageId: this.cancelacion.movimientoId, Filter: 'ACTIVO' })
    .then(response => {
      this.transacciones = response.data.data;
    }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {
      this.isLoading = false;
    });
  }else{
    this.cancelacion.transactionId = '';
    this.transacciones = [];
  }
}

//Computeds:

function movimientosFormatted(){
  if(this.movimientos.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.movimientos.map(function(e){
      chart.push({
         value: e.MovStowageId,
         label: e.MovStowageName
      })    
    })
    return chart;
  }
}

function transaccionesFormatted(){
  if(this.transacciones.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.transacciones.map(function(e){
      chart.push({
         value: e.TransacStowageId,
         label: e.TransacStowageName
      })    
    })
    return chart;
  }
}

function setCancelacion(newCancelacion) {
  this.isLoading = true;
  let _lang = this.$i18n.locale;

  this.cancelacion.CancellationId = newCancelacion.MovStowageReasonId;
  this.cancelacion.movimientoId = newCancelacion.MovStowageId;
  this.cancelacion.transactionId = newCancelacion.TransacStowageId;
  this.cancelacion.CancellationReasonEn = newCancelacion.MovStowageReasonName;
  this.cancelacion.CancellationReasonEs = newCancelacion.MovStowageReasonNameEs;
  this.cancelacion.Status = newCancelacion.FgActMovStowageReason?1:0;
  this.cancelacion.statusOrigin = newCancelacion.FgActMovStowageReason?1:0;
  let name =_lang =='en' ? newCancelacion.MovStowageReasonName : newCancelacion.MovStowageReasonNameEs;
  this.titulo = this.$t('label.edit')+this.$t('label.Cancellation')+`: ${name}`;
  this.$v.$touch();
}

export default {
  name: 'add-cancelacion-modal',
  mixins: [
    MovimientoMixin,
    ModalMixin,
  ],
  props: {
    modal: Boolean,
    CancelacionData: {
      type: Object,
      default: () => { }
    }
  },
  data,
  validations: CancelValidation,
  directives: UpperCase,
  methods: {
    submit,
    toggle,
    resetForm,
    getTransacciones,
    getMovimientos,
    setCancelacion,
    statusSelectColor,
    changeStatus,
  },
  computed: {
    movimientosFormatted,
    transaccionesFormatted,
    getTitle,
  },
  watch: {
    modal: function(val) {
      this.toggle(val);
    },
    modalActive: function(val) {
      this.$emit('update:modal', val);
    },
    CancelacionData: function(newCancelacion) {
      this.setCancelacion(newCancelacion);
    }
  }
}
</script>
<style lang="scss">
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
.modal-content-mov {
  .modal-content {
    width: 70% !important;
    margin: auto !important;
    .modal-body {
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }
}
</style>